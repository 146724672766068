import React from 'react';
import PropTypes from 'prop-types';

import RichText from '~components/RichText';

import Container from '~components/Container';

const FaqHero = ({ hero_background_image, hero_title, hero_subtitle }) => (
  <Container center background={`url(${hero_background_image && hero_background_image.url})`}>
    <RichText html={hero_title && hero_title.html} mode="faqHeroTitle" />
    <RichText html={hero_subtitle && hero_subtitle.html} mode="faqHeroSubtitle" />
  </Container>
);
FaqHero.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  hero_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_subtitle: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired
};

export default FaqHero;
